// @font-face {
//   font-family: "SF NS Rounded";
//   src: url("./assets/fonts/SFNSRounded.ttf") format("truetype");
//   font-weight: normal;
//   font-style: normal;
// }

body {
  margin: 0;
  // font-family: "SF NS Rounded";
}

.logo a {
  text-decoration: none; /* Removes underline */
  color: inherit; /* Ensures the text takes the color of the parent */

  &:hover {
    opacity: 0.8; /* Optional hover effect */
  }
}

.App {
  // font-family: "SF NS Rounded";

  // font-family: "SF NS Rounded";
  text-align: center;
  position: relative;
  overflow: hidden;
  // Circle 1 - Top Left
  &::before {
    content: "";
    position: absolute;
    top: -100px;
    left: -100px;
    width: 300px;
    height: 300px;
    border-radius: 50%;
    border: 15px solid transparent;
    background: linear-gradient(white, white) padding-box,
      linear-gradient(to top, #f3b7ad, #ec6a52, #f8b042, #eac75d) border-box;
    z-index: 0; // Behind the content
  }

  // Circle 2 - Bottom Right
  &::after {
    content: "";
    position: absolute;
    bottom: 250px;
    // top: -150;
    right: -150px;
    width: 400px;
    height: 400px;
    border-radius: 50%;
    border: 10px solid transparent;
    background: linear-gradient(white, white) padding-box,
      linear-gradient(to top, #f3b7ad, #ec6a52, #f8b042, #eac75d) border-box;
    z-index: 0; // Behind the content
  }

  // Small circle inside for additional design
  .small-circle {
    position: absolute;
    top: 20%;
    right: 15%;
    width: 150px;
    height: 150px;
    z-index: 0;
    border: 10px solid transparent;
    background: linear-gradient(white, white) padding-box,
      linear-gradient(to top, #f3b7ad, #ec6a52, #f8b042, #eac75d) border-box;
    border-radius: 50%;
  }

  // Ensure child content remains above the circles
  .content-wrapper {
    position: relative;
    z-index: 10;
  }
}

// Media Query to Hide Small Circle on Small Screens
@media (max-width: 1178px) {
  .small-circle {
    display: none;
  }
  .App::after {
    display: none; // Hides the ::after pseudo-element
  }
}
